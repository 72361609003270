import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import Config from '../../config/config';
import { isProductionEnvironment } from '../../utils/environment';
import { getClientDeviceIdSelector, getTimezoneSelector } from '../clientDevice/clientDeviceSelectors';
import { ClientAppType } from '../clientDevice/clientDeviceTypes';
import { getAuthPersonIdSelector, userHasAccessTokenSelector } from '../oauth/oauthSelectors';
import { ThunkParams } from '../types';
import { appEnteredAction, appLeftAction, createSessionAction, pageEnteredAction, pageLeftAction, updateBackgroundTimeAction, updateSessionEndAction } from './analyticsActions';
import { AnalyticsState, LogEvent, LogEventType, PageType } from './analyticsTypes';

const ANALYTICS = 'analytics';
const GOOGLE_ANALYTICS_ID = 'GTM-PV3K57H';

function storeAnalyticsThunk(AnalyticsState: AnalyticsState) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    if (localStorage) {
      localStorage.setItem(ANALYTICS, JSON.stringify(AnalyticsState));
    }
  };
}

export function getAnalyticsThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): AnalyticsState | null {
    const Analytics: any = localStorage.getItem(ANALYTICS);
    return JSON.parse(Analytics);
  };
}

export function removeAnalyticsThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): void {
    localStorage.removeItem(ANALYTICS);
  };
}

const ONE_SECOND = 1000;
const HEARTBEAT_INTERVAL = 10 * ONE_SECOND; // 10 seconds
const SESSION_LENGTH = 2 * 60 * 60 * ONE_SECOND; // 2 hours

interface Props {
  logEventType: LogEventType;
  pageType?: Nullable<PageType>;
  activityStartDate: Date;
  activityEndDate: Date;
  longValue: number;
  getState: any;
}

let beating = true;

const heartbeat = () => {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    setTimeout(() => {
      if (beating) {
        dispatch(updateSessionEndAction(Date.now()));
        dispatch(heartbeat());
      }
    }, HEARTBEAT_INTERVAL);
  };
};

const getLogEventData = (props: Props): LogEvent => {
  const { logEventType, pageType, activityStartDate, activityEndDate, longValue, getState } = props;
  const nowDate = new Date();
  const { sessionId } = getState().analytics;
  const id = timestampAndRandomCombinedUUIDString();

  return {
    id,
    logEventType,
    clientLogDate: nowDate.toISOString(),
    clientTimeZoneId: getTimezoneSelector(getState().clientDevice),
    personId: getAuthPersonIdSelector(getState()),
    clientDeviceId: getClientDeviceIdSelector(getState().clientDevice),
    sessionId,
    activityStartDate: activityStartDate.toISOString(),
    activityEndDate: activityEndDate.toISOString(),
    longValue,
    pageType,
    clientAppType: ClientAppType.WEB
  };
};

export function sendLogEventThunk(logEvents: LogEvent[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const userHasAccessToken = userHasAccessTokenSelector(getState());
    if (userHasAccessToken) {
      apiClient.post('v1/stats/logEvents', {
        data: logEvents,
        auth: true,
        suppressInternetErrorMessage: true
      });
    } else {
      apiClient.post('api/stats/logEventsNoAuth', {
        baseUrl: Config.getAuthBaseUrl(),
        data: logEvents,
        suppressInternetErrorMessage: true
      });
    }
  };
}

export function startAnalyticsThunk(analytics: AnalyticsState) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const lastSessionData = analytics;

    if (!lastSessionData) {
      const nowDate = new Date();
      const nowMs = nowDate.getTime();
      dispatch(createSessionAction({ sessionId: timestampAndRandomCombinedUUIDString(), sessionStartMs: nowMs }));
    }

    dispatch(heartbeat());
    dispatch(appEnteredThunk());
    dispatch(appStateChangeThunk());
  };
}

export function appEnteredThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const nowDate = new Date();
    const nowMs = nowDate.getTime();

    dispatch(appEnteredAction(nowDate.toISOString()));

    if (!beating) {
      beating = true;
      dispatch(heartbeat());
    }

    const { sessionStartMs, sessionEndMs, backgroundMs, sessionId } = getState().analytics;
    const backgroundTime = nowMs - sessionEndMs;
    const isNewSession = backgroundTime > SESSION_LENGTH;

    if (isNewSession) {
      if (sessionId) {
        const lastSessionStartDate = new Date(sessionStartMs);
        const lastSessionEndDate = new Date(sessionEndMs);
        const lastSessionVisible = sessionEndMs - sessionStartMs - backgroundMs;

        const logEvent = getLogEventData({
          logEventType: LogEventType.SESSION_ENDED,
          activityStartDate: lastSessionStartDate,
          activityEndDate: lastSessionEndDate,
          longValue: lastSessionVisible,
          getState
        });

        dispatch(sendLogEventThunk([logEvent]));
      }

      dispatch(createSessionAction({ sessionId: timestampAndRandomCombinedUUIDString(), sessionStartMs: nowMs }));
    } else {
      dispatch(updateSessionEndAction(nowMs));
      dispatch(updateBackgroundTimeAction(backgroundTime));
    }
    dispatch(storeAnalyticsThunk(getState().analytics));
  };
}

export function pageEnteredThunk(pageType: PageType) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const nowDate = new Date();
    const nowMs = nowDate.getTime();

    dispatch(pageEnteredAction({ page: pageType, date: nowDate.toISOString() }));
    dispatch(updateSessionEndAction(nowMs));
    dispatch(storeAnalyticsThunk(getState().analytics));
  };
}

export function pageLeftThunk(pageType: PageType) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const nowDate = new Date();
    const nowMs = nowDate.getTime();

    const { pageEnterDate } = getState().analytics;
    const pageEnteredDate = new Date(pageEnterDate);
    const pageDuration = nowMs - pageEnteredDate.getTime();

    const pageLeftEvent = getLogEventData({
      logEventType: LogEventType.PAGE_LEFT,
      pageType,
      activityStartDate: pageEnteredDate,
      activityEndDate: nowDate,
      longValue: pageDuration,
      getState
    });

    dispatch(pageLeftAction({ page: pageType, date: nowDate.toISOString() }));
    dispatch(updateSessionEndAction(nowMs));
    dispatch(sendLogEventThunk([pageLeftEvent]));
    dispatch(storeAnalyticsThunk(getState().analytics));
  };
}

export function appLeftThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const nowDate = new Date();
    const nowMs = nowDate.getTime();

    const { appEnterDate, pageType } = getState().analytics;
    const appEnteredDate = new Date(appEnterDate);
    const appDuration = nowMs - appEnteredDate.getTime();

    const appLeftEvent = getLogEventData({
      logEventType: LogEventType.APP_LEFT,
      pageType,
      activityStartDate: appEnteredDate,
      activityEndDate: nowDate,
      longValue: appDuration,
      getState
    });

    const { pageEnterDate } = getState().analytics;
    const pageEnteredDate = new Date(pageEnterDate);
    const pageDuration = nowMs - pageEnteredDate.getTime();

    const pageLeftEvent = getLogEventData({
      logEventType: LogEventType.PAGE_LEFT,
      pageType,
      activityStartDate: pageEnteredDate,
      activityEndDate: nowDate,
      longValue: pageDuration,
      getState
    });

    beating = false;
    dispatch(sendLogEventThunk([appLeftEvent, pageLeftEvent]));
    dispatch(appLeftAction(nowMs));
    dispatch(storeAnalyticsThunk(getState().analytics));
  };
}

function appStateChangeThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-expect-error: TODO add description
    } else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
      // @ts-expect-error: TODO add description
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-expect-error: TODO add description
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    function handleVisibilityChange() {
      if (document[hidden]) {
        dispatch(appLeftThunk());
      } else {
        dispatch(appEnteredThunk());
      }
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === 'undefined' || hidden === undefined || visibilityChange === undefined) {
      console.error('This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.');
    } else {
      // Handle page visibility change
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
  };
}

export function sendFacebookPixel(eventName: string) {
  if ((window as any).fbq && isProductionEnvironment()) {
    (window as any).fbq('track', eventName);
  }
}
