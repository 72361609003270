/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useRef, SyntheticEvent, forwardRef, createRef } from 'react';
import DateCalendar, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { TextField } from '../TextField';
import { Text } from '../Typography';
import { Button } from '../Button/Button';
import chevronLeftIcon from '../../assets/images/calendar-chevron-left.png';
import chevronRightIcon from '../../assets/images/calendar-chevron-right.png';
import todayCalendarIcon from '../../assets/images/today-calendar.png';
import clockIcon from '../../assets/images/clock.png';
import calendarIcon from '../../assets/images/calendar-blank.png';
import { DAYS_OF_WEEK, MONTHS, YEAR_VIEW_LIMIT } from './constants';
import classNames from 'classnames';
import { isValid, parse, setHours, setMinutes } from 'date-fns';
import { createPortal } from 'react-dom';
import { mergeArraysToMap } from '../../utils/util';
import { TextFieldProps } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';

import style from './DatePicker.scss';
import { isDateToday, tsAsTimeStr } from '../../utils/time';

function focusOnFirstFocusableChild(element: HTMLElement) {
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const focusable = Array.from(element.querySelectorAll<HTMLElement>(focusableElements));
  const { scrollX, scrollY } = globalThis;
  focusable[0]?.focus?.();
  globalThis?.scrollTo?.(scrollX, scrollY);
}

const formatWeekDay = (nameOfDay: string, daysOfWeekList: { [key: string]: string }) => {
  return <span>{daysOfWeekList[nameOfDay].slice(0, 2)}</span>; // 2 first letters od week day
};

export interface DatePickerV2Props {
  invalidDateError?: string;
  textFieldProps?: TextFieldProps;
  textFieldInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  date?: Date;
  className?: string;
  dateFormat?: string;
  timeZoneText?: string;
  disabled?: boolean;
  id: string;
  errorText?: string;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  label?: string;
  showTimeInput?: boolean;
  placement?: string;
  variant?: 'default' | 'error';
  onCalendarClose?: () => void;
  onChange?: (e: SyntheticEvent | Date | null) => void;
  onClear?: () => void;
  onCalendarOpen?: () => void;
  inTable?: boolean;
  inline?: boolean;
  additionalProps?: Object;
  monthSelection?: boolean;
  isYearPickerEnabled?: boolean;
  monthsList?: string[];
  daysOfWeekList?: string[];
  timeCaption?: string;
  disablePast?: boolean;
}

let selectedDate: any = null;

interface DateInputProps {
  textFieldInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  textFieldProps?: TextFieldProps;
  value?: string;
  previousDate: string;
  customValueChanged: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  datepickerRef: any;
  clearDate: () => void;
  disabled: boolean;
  errorText: string;
  inputId: string;
  timeZoneText: string;
  handleSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inTable: boolean;
  label: string | undefined;
  isInvalidDate: boolean;
  invalidDateError: string;
}

const DateInput = forwardRef<HTMLInputElement, DateInputProps>((Obj, ref) => {
  const {
    textFieldInputProps,
    textFieldProps,
    value,
    onClick,
    previousDate,
    customValueChanged,
    disabled,
    inputId,
    timeZoneText,
    inTable,
    handleSearchInput,
    handleInputClick,
    label,
    isInvalidDate,
    invalidDateError
  } = Obj;
  let { errorText } = Obj;
  errorText = isInvalidDate ? invalidDateError : errorText;
  const valueForTextField = customValueChanged === true ? previousDate : value;

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick(e);
    handleInputClick && handleInputClick(e);
  };

  const startIcon = (
    // <Button variant="text" type="button" disabled={disabled} onClick={handleClick} className={classNames('open-calendar-icon', style.calendarHeaderNavButton)}>
    <img src={calendarIcon} style={{ height: '16px', width: '16px' }} />
    // </Button>
  );

  return (
    <TextField
      className={classNames(style.datepickerInput)}
      disabled={disabled}
      leading={startIcon}
      error={errorText}
      id={inputId}
      label={label}
      value={valueForTextField + (valueForTextField && timeZoneText ? ` ${timeZoneText}` : '')}
      onChange={handleSearchInput}
      onClick={handleClick} // remove to be able to type date and time and uncomment startIcon button
      data-testid="date-picker-input"
      boxShadow={!inTable}
      classNameLabel={classNames(inTable && style.labelTableView)}
      autoComplete="off"
      {...(textFieldProps as any)}
    />
  );
});

const DatePicker: React.FC<DatePickerV2Props> = ({
  textFieldInputProps,
  textFieldProps,
  className = '',
  date = null,
  dateFormat = '',
  disabled = false,
  timeZoneText = '',
  errorText = '',
  id,
  label = '',
  minDate,
  maxDate,
  minTime,
  maxTime,
  placement,
  onCalendarClose,
  onCalendarOpen,
  onChange,
  onClear,
  inTable = false,
  inline = false,
  additionalProps = {},
  monthSelection,
  isYearPickerEnabled,
  invalidDateError = 'InvalidDate',
  monthsList = MONTHS,
  daysOfWeekList = DAYS_OF_WEEK,
  timeCaption,
  disablePast
}) => {
  const [startDate, setStartDate] = useState<Date | null>(date);
  const [previousStartDate, setPreviousStartDate] = useState<Date | null>(date);
  const [previousDate, setPreviousDate] = useState<string>('');
  const [customValueChanged, setCustomValueChanged] = useState<boolean>(false);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState<boolean>(false);
  const [showYearPicker, setShowYearPicker] = useState<boolean>(false);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);

  let datepickerRef: any = useRef(null);
  const ref = createRef<HTMLInputElement>();
  const reactPortalRef = React.createRef<HTMLDivElement>();

  const dateFormatLocale = 'MM/dd/yyyy hh:mm a';
  const daysOfWeekLocaleMapping = mergeArraysToMap(DAYS_OF_WEEK, daysOfWeekList);

  const inlineClass = inline && style.inlineDatePicker;
  const isDisabledClass = disabled && style.disabledDatePicker;

  let minDateMinTime;
  let minDateMaxTime;
  const isMinDate = startDate && startDate.toDateString() === minDate?.toDateString();
  if (isMinDate && disablePast) {
    minDateMinTime = minDate;
    minDateMaxTime = setHours(setMinutes(new Date(), 59), 23);
  }

  const isApplyButtonDisabled =
    !startDate ||
    (minTime && startDate ? startDate?.getTime() < minTime?.getTime() : false) ||
    (minDateMinTime && startDate ? startDate?.getTime() < minDateMinTime?.getTime() : false);

  useEffect(() => {
    setStartDate(date);
    setPreviousStartDate(date);
  }, [date]);

  function handleOnChange(date: Date | null) {
    setStartDate(date);
    selectedDate = date;
    // on change should be triggered only when date is selected in day view
    if (!showMonthYearPicker && !showYearPicker) {
      onChange && onChange(date);
      selectDate();
    }
    setCustomValueChanged(false);
  }

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const parsedDate = parse(input, dateFormatLocale, new Date());
    setCustomValueChanged(true);
    setPreviousDate(input);
    if (isValid(parsedDate)) {
      setStartDate(parsedDate);
      setPreviousStartDate(parsedDate);
      handleOnChange(parsedDate);
      setIsInvalidDate(false);
    } else if (input.length === 0) {
      handleOnChange(null);
    } else {
      setIsInvalidDate(true);
    }
  };

  function clearDate() {
    setStartDate(null);
    selectedDate = null;
    onClear && onClear();
  }

  function selectDate() {
    setIsInvalidDate(false);
    onChange && onChange(selectedDate);
  }

  function applyDate() {
    if (!startDate) {
      const now = new Date();
      handleOnChange(now);
      setPreviousStartDate(now);
    } else {
      setPreviousStartDate(startDate);
    }
    datepickerRef.setOpen(false);
    setShowTimePicker(false);
  }

  function cancelDate() {
    handleOnChange(previousStartDate);
    datepickerRef.setOpen(false);
    setShowTimePicker(false);
  }

  const handleMonthViewClick = () => {
    setShowMonthYearPicker(!showMonthYearPicker);
  };

  const handleYearViewClick = () => {
    setShowYearPicker(!showYearPicker);
  };

  const getCustomHeaderAttributes = (headerProps: ReactDatePickerCustomHeaderProps) => {
    const {
      date,
      decreaseMonth,
      increaseMonth,
      changeYear,
      decreaseYear,
      increaseYear,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      prevYearButtonDisabled,
      nextYearButtonDisabled
    } = headerProps;

    const currentDate = date.getDate();
    const currentMonth = monthsList[date.getMonth()];
    const currentYear = date.getFullYear();

    switch (true) {
      case showYearPicker: {
        const minYear = minDate?.getFullYear();
        const maxYear = maxDate?.getFullYear();
        const effectiveNextYear = maxYear && currentYear + YEAR_VIEW_LIMIT <= maxYear ? maxYear : currentYear + YEAR_VIEW_LIMIT;
        const effectivePrevYear = minYear && currentYear - YEAR_VIEW_LIMIT >= minYear ? minYear : currentYear - YEAR_VIEW_LIMIT;
        const currentYearPositionInYearView = currentYear % YEAR_VIEW_LIMIT || YEAR_VIEW_LIMIT;

        return {
          toggleView: (
            <Button variant="text" disabled={!isYearPickerEnabled} className={style.viewToggleBtn as string} onClick={handleYearViewClick}>
              {`${currentMonth} ${currentDate} ${currentYear}`}
            </Button>
          ),
          isDecreaseAllowed: effectivePrevYear < currentYear - (currentYearPositionInYearView - 1),
          isIncreaseAllowed: effectiveNextYear > currentYear + (YEAR_VIEW_LIMIT - currentYearPositionInYearView),
          onDecrease: changeYear.bind(null, effectivePrevYear),
          onIncrease: changeYear.bind(null, effectiveNextYear)
        };
      }
      case showMonthYearPicker: {
        return {
          toggleView: (
            <span>
              <Button variant="text" disabled={!monthSelection} className={style.viewToggleBtn as string} onClick={handleMonthViewClick}>
                {currentMonth}
              </Button>
              <Button variant="text" disabled={!isYearPickerEnabled} className={style.viewToggleBtn as string} onClick={handleYearViewClick}>
                {currentYear}
              </Button>
            </span>
          ),
          isDecreaseAllowed: !prevMonthButtonDisabled,
          isIncreaseAllowed: !nextMonthButtonDisabled,
          onDecrease: decreaseMonth,
          onIncrease: increaseMonth
        };
      }
      default:
        return {
          toggleView: (
            <span>
              <Button variant="text" disabled={!monthSelection} className={style.viewToggleBtn as string} onClick={handleMonthViewClick}>
                {currentMonth}
              </Button>
              <Button variant="text" disabled={!isYearPickerEnabled} className={style.viewToggleBtn as string} onClick={handleYearViewClick}>
                {currentYear}
              </Button>
            </span>
          ),
          isDecreaseAllowed: !prevMonthButtonDisabled,
          isIncreaseAllowed: !nextMonthButtonDisabled,
          onDecrease: decreaseMonth,
          onIncrease: increaseMonth
        };
    }
  };

  return (
    <div className={classNames(style.datepicker, inlineClass, isDisabledClass, className)}>
      <DateCalendar
        ref={(r: any) => (datepickerRef = r)}
        formatWeekDay={(dow: string) => formatWeekDay(dow, daysOfWeekLocaleMapping)}
        renderCustomHeader={(headerProps: ReactDatePickerCustomHeaderProps) => {
          const opts = getCustomHeaderAttributes(headerProps);
          return (
            <>
              <div className={style.datepickerHeaderTimeCaption}>{timeCaption}</div>
              <div className={style.datepickerHeader}>
                {opts.toggleView}
                <div className={style.calendarHeaderNav}>
                  <button
                    className={style.calendarHeaderNavButton}
                    aria-label={showYearPicker ? 'decrease-year' : 'decrease-month'}
                    disabled={!opts.isDecreaseAllowed}
                    onClick={opts.onDecrease}
                  >
                    <img className={style.calendarHeaderNavIcon} src={chevronLeftIcon} />
                  </button>
                  <button
                    className={style.calendarHeaderNavButton}
                    aria-label={showYearPicker ? 'increase-year' : 'increase-month'}
                    disabled={!opts.isIncreaseAllowed}
                    onClick={opts.onIncrease}
                  >
                    <img className={style.calendarHeaderNavIcon} src={chevronRightIcon} />
                  </button>
                </div>
              </div>
              <hr
                style={{
                  margin: 0,
                  marginBottom: '12px',
                  border: 0,
                  height: '0px !important'
                }}
              />
            </>
          );
        }}
        popperContainer={({ children }) => {
          const wrapper = (element: any) => {
            if (globalThis?.document) return createPortal(element, globalThis.document.body);
            return element;
          };
          const element = (
            <div
              ref={reactPortalRef}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onTouchStart={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onPointerDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              className={style.datepickerPopup}
            >
              {children}
            </div>
          );
          return wrapper(element);
        }}
        calendarClassName={classNames(style.datepickerCal)}
        dateFormat={dateFormatLocale}
        selected={startDate}
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        onCalendarClose={onCalendarClose}
        onCalendarOpen={() => {
          const calendarHtmlElement = reactPortalRef.current as HTMLElement;
          focusOnFirstFocusableChild(calendarHtmlElement);
          onCalendarOpen?.();
        }}
        onChange={handleOnChange}
        handleChange={handleOnChange}
        onClickOutside={() => {
          cancelDate();
          setShowMonthYearPicker(false);
          setShowYearPicker(false);
        }}
        customInput={
          <DateInput
            textFieldInputProps={textFieldInputProps}
            previousDate={previousDate}
            customValueChanged={customValueChanged}
            ref={ref}
            datepickerRef={datepickerRef}
            clearDate={clearDate}
            disabled={disabled}
            inputId={id}
            errorText={errorText}
            timeZoneText={timeZoneText}
            handleSearchInput={handleSearchInput}
            inTable={inTable}
            label={label}
            invalidDateError={invalidDateError}
            isInvalidDate={isInvalidDate}
          />
        }
        placeholderText={label}
        minDate={minDate}
        minTime={minTime || minDateMinTime}
        maxTime={maxTime || minDateMaxTime}
        // @ts-expect-error: expected type issue
        popperPlacement={placement}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        showTimeSelect={showTimePicker}
        showTimeSelectOnly={showTimePicker}
        timeCaption={timeCaption}
        todayButton={
          <div className={classNames(style.todayButtonContent, isDateToday(startDate) ? style.todayButtonContentSelected : {})}>
            <img className={style.todayCalendarIcon} src={todayCalendarIcon} />
            <Text variant="sm">Today</Text>
          </div>
        }
        maxDate={maxDate}
        inline={inline}
        readOnly={disabled}
        {...additionalProps}
      >
        <div>
          <div
            className={style.timeHeaderContainer}
            onClick={() => {
              setShowTimePicker(!showTimePicker);
            }}
          >
            <img className={style.todayCalendarIcon} src={clockIcon} />
            <Text variant="md" className={style.timeHeaderText}>
              {tsAsTimeStr(startDate?.toISOString())}
            </Text>
          </div>
          <div className={style.bottomButtonsContainer}>
            <Button variant="outlined" className={style.bottomButton} onClick={cancelDate}>
              Cancel
            </Button>
            <Button variant="contained" className={style.bottomButton} onClick={applyDate} disabled={isApplyButtonDisabled}>
              Apply
            </Button>
          </div>
        </div>
      </DateCalendar>
    </div>
  );
};

DatePicker.defaultProps = {
  placement: 'bottom'
};

export default DatePicker;
