import * as m from 'moment-timezone';
import React from 'react';
import DatePicker from 'react-datepicker';
import { DEFAULT_TIMEZONE_ID } from '../../utils/time';

interface Props {
  moment: any;
  disableCalendar: boolean;
  disableTime: boolean;
  timeZone?: string;
  customWidth: string;
  popperPlacement: string;
  timeIntervals: number;
  datePickerClass?: string; //external component does not support nullable types, so we can't apply Nullable<> type here
  disabledManualInput?: Nullable<boolean>;
  onChange: Function;
}

interface State {
  moment: any;
}

class TimeInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { disableTime, moment } = props;

    if (disableTime) {
      moment?.set({ hours: 0, minutes: 0 });
    }
    moment?.set({ second: 0, millisecond: 0 });
    this.state = { moment };
  }

  componentDidUpdate = (prevProps) => {
    const { disableTime, moment } = this.props;

    if (moment != prevProps.moment) {
      if (disableTime) {
        moment?.set({ hours: 0, minutes: 0 });
      }
      moment?.set({ second: 0, millisecond: 0 });

      this.setState({ moment });
    }
  };

  onChange = (date) => {
    const d = date.toString().split(' ').slice(1, 5).join(' ');
    const moment = m.tz(d, 'MMM DD YYYY HH:mm:ss', this.props.timeZone || DEFAULT_TIMEZONE_ID);

    this.props.onChange(moment);
    this.setState({ moment });
  };

  render() {
    const { customWidth, disableCalendar, disableTime, timeIntervals, popperPlacement, datePickerClass, disabledManualInput } = this.props;
    const { moment } = this.state;

    let dateFormat = 'MMMM d, yyyy h:mm aa';
    const timeFormat = 'h:mm aa';

    if (disableTime) dateFormat = 'MM/dd/yyyy';
    if (disableCalendar) dateFormat = timeFormat;

    // DatePicker will only display the time in the local timezone
    const date = moment ? new Date(moment.format('YYYY-MM-DDTHH:mm:ss')) : null;

    return (
      <div
        className="react-datepicker-custom-container"
        style={{
          width: customWidth
        }}
      >
        <DatePicker
          selected={date}
          className={datePickerClass}
          onChange={this.onChange}
          showTimeSelect={!disableTime}
          showTimeSelectOnly={disableCalendar}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals || 15}
          timeCaption="time"
          /* @ts-expect-error: expected type issue */
          popperPlacement={popperPlacement}
          onFocus={disabledManualInput ? (e) => e.target.blur() : undefined}
        />
      </div>
    );
  }
}

export default TimeInput as any;
