import { TableBody } from '@material-ui/core';
import { List, MenuItem, Paper, Popover, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { Text } from '../../components/Typography/Text';
import { getOrganizationByIdSelector } from '../../redux/organization/organizationSelectors';
import { getAllOrganizationsThunk } from '../../redux/organization/organizationThunks';
import {
  getPermissionRoleByIdSelector,
  getPermissionRoleTypeByIdSelector,
  getPersonByIdSelector,
  getPersonPermissionRoleByIdSelector,
  getPersonPermissionRoleIdsByPersonIdSelector,
  hasAuthUserAdminPermissionSelector
} from '../../redux/person/personSelector';
import { getAllPermissionRolesThunk, getPersonPermissionsByOrgIdThunk, putPersonPermissionRoleThunk, removePersonPermissionRoleThunk } from '../../redux/person/personThunks';
import { ReduxState } from '../../redux/types';
import style from './AdminUserPermissions.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const headerStyles = {
  fontSize: 16,
  fontWeight: 'bold'
};

const dataStyles = {
  fontSize: 14
};

const AdminUserPermissions: React.FC<Props> = ({
  organizationById,
  getPersonPermissionsByOrgId,
  getAllOrganizations,
  removePersonPermissionRole,
  putPersonPermissionRole,
  getAllPermissionRoles,
  hasAuthUserAdminPermission,
  personPermissionRoleIdsByPersonId,
  permissionRoleTypeById,
  permissionRoleById,
  personById,
  personPermissionRoleById
}) => {
  if (!hasAuthUserAdminPermission) {
    return null;
  }
  const [selectedOrgId, setSelectedOrgId] = React.useState<Nullable<string>>(null);
  const [addAnchorEl, setAddAnchorEl] = React.useState<Nullable<HTMLElement>[]>(Array(Object.values(permissionRoleById).length).fill(null));
  const [addOpen, setAddOpen] = React.useState<boolean[]>(Array(Object.values(permissionRoleById).length).fill(false));

  useEffect(() => {
    getAllOrganizations();
    getAllPermissionRoles();
  }, []);

  const handleAddButtonClick = (index: number, event: any) => {
    const addAnchorElCopy = [...addAnchorEl];
    addAnchorElCopy[index] = event.currentTarget;
    setAddAnchorEl(addAnchorElCopy);
    const addOpenCopy = [...addOpen];
    addOpenCopy[index] = true;
    setAddOpen(addOpenCopy);
  };

  const handleAddButtonClose = (index: number) => {
    const addAnchorElCopy = [...addAnchorEl];
    addAnchorElCopy[index] = null;
    setAddAnchorEl(addAnchorElCopy);
    const addOpenCopy = [...addOpen];
    addOpenCopy[index] = false;
    setAddOpen(addOpenCopy);
  };

  const orgIds: { name: string; id?: string }[] = [];
  Object.values(organizationById).forEach((org) => {
    orgIds.push({ name: org.name, id: org.id });
  });

  const handleOrgIdChange = (event, value) => {
    if (value?.id) {
      setSelectedOrgId(value.id);
      getPersonPermissionsByOrgId(value.id);
    }
  };

  const onRemovePermissionRole = async (personPermissionRoleId: string, permissionRoleName: string) => {
    if (confirm(`Are you sure you want to remove this permission role: ${permissionRoleName}?`)) {
      await removePersonPermissionRole(personPermissionRoleId);
    }
  };

  // event handler to add a permission role to a person
  const onAddPermissionRole = async (index: number, personId: string, permissionRoleId: string) => {
    await putPersonPermissionRole(personId, permissionRoleId);
    handleAddButtonClose(index);
  };

  const selectedValue = orgIds.find((org) => org.id === selectedOrgId) || null;

  return (
    <NavSideBarContentWrapper headerText="User Permissions">
      <div className={style.orgHeader}>
        <Text variant="sm" className={style.labelSelect}>
          Choose Organization
        </Text>
        <CarePlanAutocomplete
          options={orgIds}
          value={selectedValue} // Ensure value is not undefined
          onChange={handleOrgIdChange}
          getOptionLabel={(option) => (option as any).name || ''}
          isOptionEqualToValue={(option, value) => (option as any).id === (value as any).id}
          PaperComponent={CarePlanAutocompletePaper}
          PopperComponent={CarePlanAutocompletePopper}
          renderInput={(params) => <CarePlanAutocompleteTextField {...params} placeholder="Select" />}
          renderOption={(props, option, state) => (
            <li {...props} key={(option as any).id}>
              <div>{(option as any).name}</div>
            </li>
          )}
        />
      </div>
      <div className={style.tableContainer}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 100, ...headerStyles }}>Name</TableCell>
                <TableCell sx={{ width: 100, ...headerStyles }}>Email</TableCell>
                <TableCell sx={{ width: 500, ...headerStyles }}>Permissions</TableCell>
                <TableCell sx={{ width: 190, ...headerStyles }}>OrganizationId</TableCell>
                <TableCell sx={{ width: 190, ...headerStyles }}>PersonId</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(personPermissionRoleIdsByPersonId).map((personId, index) => {
                const person = personById[personId];
                const permissionRoleIds: string[] = [];
                if (person?.organizationId === selectedOrgId) {
                  return (
                    <TableRow key={personId}>
                      <TableCell component="th" scope="row" sx={dataStyles}>
                        {person.givenNames} {person.familyName}
                      </TableCell>
                      <TableCell sx={dataStyles}>{person.email}</TableCell>
                      <TableCell sx={dataStyles}>
                        {personPermissionRoleIdsByPersonId[personId] &&
                          personPermissionRoleIdsByPersonId[personId].map((personPermissionRoleId) => {
                            const personPermissionRole = personPermissionRoleById[personPermissionRoleId];
                            if (personPermissionRole) {
                              const permissionRole = permissionRoleById[personPermissionRole.permissionRoleId];
                              if (permissionRole) {
                                permissionRoleIds.push(permissionRole.id);
                                const permissionRoleName = permissionRoleTypeById[permissionRole.permissionRoleTypeId].name;
                                return (
                                  <div key={personPermissionRoleId} className={style.permissionPill}>
                                    <Text variant="sm">
                                      {permissionRoleName}
                                      <div className={style.permissionPillMinus} onClick={() => onRemovePermissionRole(personPermissionRoleId, permissionRoleName)}>
                                        x
                                      </div>
                                    </Text>
                                  </div>
                                );
                              }
                            }
                            return null;
                          })}
                        <div className={style.permissionPillAdd} onClick={(event) => handleAddButtonClick(index, event)}>
                          +
                        </div>
                        <Popover
                          anchorEl={addAnchorEl[index]}
                          open={addOpen[index] || false}
                          onClose={() => handleAddButtonClose(index)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          className={style.popover}
                        >
                          <List className={style.contextMenu}>
                            {Object.keys(permissionRoleById).map((permissionRoleId) => {
                              const permissionRoleList = permissionRoleById[permissionRoleId];
                              if (!permissionRoleList) return null;
                              const permissionRoleTypeList = permissionRoleTypeById[permissionRoleList.permissionRoleTypeId];
                              if (!permissionRoleTypeList) return null;
                              if (permissionRoleIds.includes(permissionRoleList.id)) return null;
                              return (
                                <MenuItem key={permissionRoleId} onClick={() => onAddPermissionRole(index, personId, permissionRoleId)}>
                                  <Text variant="md">{permissionRoleTypeList?.name}</Text>
                                </MenuItem>
                              );
                            })}
                          </List>
                        </Popover>
                      </TableCell>
                      <TableCell sx={dataStyles}>{person.organizationId}</TableCell>
                      <TableCell sx={dataStyles}>{person.id}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    organizationById: getOrganizationByIdSelector(state),
    personById: getPersonByIdSelector(state),
    hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
    personPermissionRoleIdsByPersonId: getPersonPermissionRoleIdsByPersonIdSelector(state),
    personPermissionRoleById: getPersonPermissionRoleByIdSelector(state),
    permissionRoleTypeById: getPermissionRoleTypeByIdSelector(state),
    permissionRoleById: getPermissionRoleByIdSelector(state)
  }),
  {
    getPersonPermissionsByOrgId: getPersonPermissionsByOrgIdThunk,
    getAllOrganizations: getAllOrganizationsThunk,
    removePersonPermissionRole: removePersonPermissionRoleThunk,
    putPersonPermissionRole: putPersonPermissionRoleThunk,
    getAllPermissionRoles: getAllPermissionRolesThunk
  }
);

export default compose(connectRedux)(AdminUserPermissions);
