import React, { useEffect } from 'react';
import { Color } from '../../utils/color';
import { useOutletContext } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import style from './NavSideBarContentWrapper.scss';
import { Display } from '../../components/Typography';
import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import { getBreadCrumbsWithLastText, fromDefault, renderBreadCrumbs } from '../../utils/breadcrumbs';
import { BreadCrumbData, BreadCrumbType, SelectOption } from '../../redux/common/types';
import { NAV_BROKEN_ENABLED } from '../../navigation/NavSideBar/NavSideBar';
import classNames from 'classnames';

interface Props {
  headerText?: string | Function;
  headerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
  breadCrumbs?: BreadCrumbData[];
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) => void>;
  defaultCurrentCrumb?: Nullable<BreadCrumbData>;
  defaultCrumbHistory?: Nullable<BreadCrumbData[]>;
  overrideTitle?: Nullable<string>;
  overrideUrl?: Nullable<string>;
  overrideType?: Nullable<BreadCrumbType>;
  overrideValueOptions?: Nullable<SelectOption[]>;
  overrideSelectedIndex?: Nullable<number>;
  refreshOnChangeRef?: Nullable<any>;
}

const NavSideBarContentWrapper: React.FC<Props> = ({
  children,
  headerText,
  headerClassName,
  contentClassName,
  breadCrumbs,
  appendBreadCrumbs,
  defaultCurrentCrumb,
  defaultCrumbHistory,
  overrideTitle,
  overrideUrl,
  overrideType,
  overrideValueOptions,
  overrideSelectedIndex,
  refreshOnChangeRef
}) => {
  const [broken, toggled, setToggled] = useOutletContext() as any;

  useEffect(() => {
    if (appendBreadCrumbs && defaultCurrentCrumb && defaultCrumbHistory) {
      appendBreadCrumbs(fromDefault(defaultCurrentCrumb, overrideUrl, overrideTitle, overrideType, overrideSelectedIndex, overrideValueOptions), defaultCrumbHistory, false);
    }
  }, [
    location,
    overrideTitle,
    overrideUrl,
    overrideType,
    overrideSelectedIndex,
    overrideValueOptions,
    refreshOnChangeRef,
    defaultCurrentCrumb?.title,
    defaultCrumbHistory?.length
  ]);

  const breadCrumbsWithLastText = getBreadCrumbsWithLastText(breadCrumbs);

  return (
    <div className={style.container}>
      {!!broken && NAV_BROKEN_ENABLED && (
        <IconButton
          aria-label="menu"
          onClick={() => setToggled(!toggled)}
          style={{ position: 'absolute', top: '30px', left: '40px', alignSelf: 'flex-start', color: Color.DARK_BLUE, backgroundColor: Color.LIGHT_BLUE }}
        >
          <MenuIcon />
        </IconButton>
      )}
      {(breadCrumbsWithLastText || headerText) && (
        <div className={classNames(style.header, headerClassName)}>
          {breadCrumbsWithLastText && (
            <div className={style.headerItem}>
              <BreadCrumbs aria-label="breadcrumb">
                {renderBreadCrumbs(breadCrumbsWithLastText).map((breadCrumb, index) => {
                  return <span key={index}>{breadCrumb}</span>;
                })}
              </BreadCrumbs>
            </div>
          )}
          {headerText && <div className={style.headerItem}>{typeof headerText === 'function' ? headerText() : <Display variant="sm">{headerText}</Display>}</div>}
        </div>
      )}
      <div className={classNames(style.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default NavSideBarContentWrapper;
