import { Box, Hidden } from '@material-ui/core';
import { FULL_SLEEP_MOBILE_IMAGE_URL } from 'assets/images/images';
import React from 'react';
import style from './AccountMobileHeader.scss';

export const AccountMobileHeader: React.FC = () => (
  /* @ts-expect-error: TODO add description */
  <Hidden mdUp>
    <Box className={style.mobileHeader}>
      <img className={style.titleBox} src={FULL_SLEEP_MOBILE_IMAGE_URL} alt="Full Sleep" />
    </Box>
  </Hidden>
);
