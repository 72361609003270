import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { userHasAccessTokenSelector } from '../redux/oauth/oauthSelectors';
import { RouteNames } from '../utils/navigationUtils';

const RouteNotFound = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector(userHasAccessTokenSelector);
  const redirectUrl = RouteNames.LOGIN;

  // Check if the user is authenticated
  if (!isLoggedIn) {
    // If not authenticated, redirect to the login page
    return <Navigate to={redirectUrl} replace />;
  }

  // If authenticated, render the child routes
  return <Navigate to={RouteNames.HOME} replace />;
};

export default RouteNotFound;
