import { BreadCrumbData, Error } from '../common/types';
import { Organization } from '../organization/organizationTypes';
import { Person, PersonPermissionRole } from '../person/personTypes';

export interface OauthUser {
  readonly email: string;
  readonly id: string;
  readonly fullImageUrl?: Nullable<string>;
  readonly fullName?: Nullable<string>;
  readonly familyName?: Nullable<string>;
  readonly givenNames?: Nullable<string>;
  readonly phoneNumber?: Nullable<string>;
  readonly created: string;
  readonly modified: string;
}

export interface OauthRegisteredUser {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
}

export interface OauthState {
  readonly accessToken?: Nullable<string>;
  readonly expiresIn?: Nullable<number>;
  readonly expires?: Nullable<number>;
  readonly refreshToken?: Nullable<string>;
  readonly refreshing: boolean;
  readonly personId?: Nullable<string>;
  readonly isConfirming: boolean;
  readonly confirmed: boolean;
  readonly passwordResetStatus?: Nullable<PasswordResetStatusType>;
  readonly passwordResetError?: Nullable<Error>;
  readonly acceptInvitedSuccessfull: boolean;
  readonly acceptInviteError?: Nullable<Error>;
  readonly initialAuthFlowLoading: boolean;
  readonly isLoading: boolean;
  readonly isLoadingDeleteAccountNotifySupportEmail: boolean;
  readonly error: Error;
  readonly errorDeleteAccountNotifySupportEmail?: Nullable<Error>;
  readonly registeredUser: OauthRegisteredUser;
  readonly breadCrumbs: BreadCrumbData[];
  readonly demoAccessCodes: DemoAccessCode[];
}

export interface LoginResponsePayload {
  readonly access_token: string;
  readonly expires_in: string;
  readonly expires: string;
  readonly refresh_token: string;
  readonly person?: Nullable<Person>;
  readonly organization?: Nullable<Organization>;
  readonly personPermissionRoles?: Nullable<PersonPermissionRole[]>;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface LogoutPayload {
  clientDeviceId: string;
}

export interface ResetPasswordEmailPayload {
  email: string;
}

export interface ResetPasswordPayload {
  password: string;
}

export interface RegisterPayload {
  email: string;
  password: string;
  givenNames: string;
  familyName: string;
  taCAgreedTs?: Nullable<string>;
  doNotAuthorizeSession?: Nullable<boolean>;
}

export interface RegisterUserEmailPayload {
  email: string;
}

export interface RegisterUserFullNamePayload {
  firstName: string;
  lastName: string;
}

export enum PasswordResetStatusType {
  RESET_START = 'RESET_START',
  RESET_COMPLETE = 'RESET_COMPLETE',
  RESET_FAILED = 'RESET_FAILED'
}

export interface AppendBreadcrumbPayload {
  breadCrumbData: BreadCrumbData;
  defaultCrumbHistory: BreadCrumbData[];
  isOldApp?: boolean;
}

export interface DemoAccessCode {
  id: string;
  accessCode: string;
  personId?: Nullable<string>;
  creatorPersonId: string;
  description?: Nullable<string>;
  startTs?: Nullable<string>;
  endTs?: Nullable<string>;
  created?: Nullable<string>;
  modified?: Nullable<string>;
}

export interface DemoAccessCodesResponsePayload {
  accessCodes: DemoAccessCode[];
  personById: { [key: string]: Person };
}

// email, password
export interface AcceptAccountInviteRequest {
  email: string;
  password: string;
}
