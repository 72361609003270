import { TableBody } from '@material-ui/core';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { PermissionRole } from 'redux/person/personTypes';
import { keys } from 'ts-transformer-keys';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { getOrganizationByIdSelector } from '../../redux/organization/organizationSelectors';
import { getAllOrganizationsThunk } from '../../redux/organization/organizationThunks';
import {
  getPermissionRoleByIdSelector,
  getPermissionRoleTypeByIdSelector,
  getPersonByIdSelector,
  getPersonPermissionRoleByIdSelector,
  getPersonPermissionRoleIdsByPersonIdSelector,
  hasAuthUserAdminPermissionSelector
} from '../../redux/person/personSelector';
import { getAllPermissionRolesThunk, getPersonPermissionsByOrgIdThunk, putPersonPermissionRoleThunk, removePersonPermissionRoleThunk } from '../../redux/person/personThunks';
import { ReduxState } from '../../redux/types';
import style from './AdminPermissionRoles.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const headerStyles = {
  fontSize: 16,
  fontWeight: 'bold'
};

const dataStyles = {
  fontSize: 14
};

const keysOfPermissionRole = keys<PermissionRole>();

const AdminPermissionRoles: React.FC<Props> = ({ getAllPermissionRoles, hasAuthUserAdminPermission, permissionRoleTypeById, permissionRoleById }) => {
  if (!hasAuthUserAdminPermission) {
    return null;
  }

  useEffect(() => {
    getAllPermissionRoles();
  }, []);

  const permissionRoleFieldExlcusions = ['permissionRoleTypeId', 'id', 'created', 'modified', 'permissionRoleType', 'permissionRoleTypeId'];

  const permissionRoleKeys = keysOfPermissionRole.filter((permissionRoleKey) => !permissionRoleFieldExlcusions.includes(permissionRoleKey));

  return (
    <NavSideBarContentWrapper headerText="User Permissions">
      <div className={style.tableContainer}>
        <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 100, ...headerStyles }}>Permission Role/Permission</TableCell>
                {permissionRoleKeys.map((permissionRoleKey) => {
                  return (
                    <TableCell key={permissionRoleKey} sx={headerStyles}>
                      {permissionRoleKey}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(permissionRoleById).map((permissionRoleId, index) => {
                const permissionRole = permissionRoleById[permissionRoleId];
                const permissionRoleType = permissionRoleTypeById[permissionRole.permissionRoleTypeId];
                if (!permissionRoleType) {
                  return null;
                }
                return (
                  <TableRow key={permissionRoleId}>
                    <TableCell component="th" scope="row" sx={dataStyles}>
                      {permissionRoleType.name}
                    </TableCell>
                    {permissionRoleKeys.map((permissionRoleKey) => (
                      <TableCell key={permissionRoleKey} sx={headerStyles}>
                        {permissionRole[permissionRoleKey] ? 'X' : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    organizationById: getOrganizationByIdSelector(state),
    personById: getPersonByIdSelector(state),
    hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
    personPermissionRoleIdsByPersonId: getPersonPermissionRoleIdsByPersonIdSelector(state),
    personPermissionRoleById: getPersonPermissionRoleByIdSelector(state),
    permissionRoleTypeById: getPermissionRoleTypeByIdSelector(state),
    permissionRoleById: getPermissionRoleByIdSelector(state)
  }),
  {
    getPersonPermissionsByOrgId: getPersonPermissionsByOrgIdThunk,
    getAllOrganizations: getAllOrganizationsThunk,
    removePersonPermissionRole: removePersonPermissionRoleThunk,
    putPersonPermissionRole: putPersonPermissionRoleThunk,
    getAllPermissionRoles: getAllPermissionRolesThunk
  }
);

export default compose(connectRedux)(AdminPermissionRoles);
