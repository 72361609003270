import React, { useState } from 'react';
import { Text } from '../../../../../components/Typography';
import ActivityItem from '../ActivityItem/ActivityItem';
import style from './ActivityTab.scss';
import classNames from 'classnames';
import { RenderedCareFeedItem } from '../../../../../redux/carePlan/carePlanTypes';

interface Props {
  editCareFeedItemDisabled: boolean;
  pastCareFeedItems: RenderedCareFeedItem[];
  removeCareFeedItemDisabled: boolean;
  upcomingCareFeedItems: RenderedCareFeedItem[];
  hidePast?: boolean;
  hideUpcoming?: boolean;
  onEditCareFeedItemClick: (careFeedItemId: string) => void;
  onRemoveCareFeedItemClick: (careFeedItemId: string) => void;
}

const ActivityTab: React.FC<Props> = ({
  editCareFeedItemDisabled,
  pastCareFeedItems,
  removeCareFeedItemDisabled,
  upcomingCareFeedItems,
  hidePast,
  hideUpcoming,
  onEditCareFeedItemClick,
  onRemoveCareFeedItemClick
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const careFeedItemsActive = (activeTab === 0 || hidePast) && !hideUpcoming ? upcomingCareFeedItems : pastCareFeedItems;

  return (
    <div className={style.activityContainer}>
      <Text variant="md">Activity</Text>
      <div className={style.tabs}>
        {!hideUpcoming && (
          <button className={classNames(style.tab, activeTab === 0 || hidePast ? style.active : {})} onClick={() => setActiveTab(0)}>
            Upcoming
          </button>
        )}
        {!hidePast && (
          <button className={classNames(style.tab, activeTab === 1 || hideUpcoming ? style.active : {})} onClick={() => setActiveTab(1)}>
            Past
          </button>
        )}
      </div>
      <div className={style.feedAndResponsesContainer}>
        {careFeedItemsActive.map((careFeedItem: RenderedCareFeedItem, index: number) => {
          const triggerTitle = careFeedItem?.triggerTitle;
          return (
            <React.Fragment key={index}>
              {triggerTitle && (
                <Text variant="md" className={style.triggerTitle}>
                  {triggerTitle}
                </Text>
              )}
              <ActivityItem
                question={careFeedItem?.question}
                questionLabel={careFeedItem?.questionLabel}
                quesitonIconType={careFeedItem.quesitonIconType}
                hideConnector={careFeedItem?.hideConnector}
                hideDialog={careFeedItem?.hideDialog}
                status={careFeedItem?.status}
                editDisabled={editCareFeedItemDisabled}
                removeDisabled={removeCareFeedItemDisabled}
                onRemoveClick={() => onRemoveCareFeedItemClick(careFeedItem?.id)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityTab;
