import { DateTime } from 'luxon';
import queryStringParser from 'query-string';
import { DeviceShipment, FirmwareType, HwRevision, HwRevisionFirmware } from '../redux/device/deviceTypes';

export const isClient = () => {
  return typeof window !== 'undefined' && window?.__data__;
};

export const isEmpty = (value: any) => value === undefined || value === null || value === '';
export const isListNotEmpty = (value: any) => value !== undefined && value !== null && value.length !== 0;
export const isContainsDigit = (value: string): boolean => !isEmpty(value) && /^(?=.*\d).+$/i.test(value);
export const isContainsNoWhitespace = (value: string): boolean => !isEmpty(value) && /^(?=\S+$).+$/i.test(value);
export const isContainsLowercaseLetter = (value: string): boolean => !isEmpty(value) && /[a-z]/.test(value);
export const isContainsUppercaseLetter = (value: string): boolean => !isEmpty(value) && /[A-Z]/.test(value);
export const isContainsSpecialChar = (value: string): boolean => !isEmpty(value) && /[^a-zA-Z\d]/.test(value);
export const isContainsMinChar = (value: string): boolean => !isEmpty(value) && value.length >= 8;

export function isPasswordValid(value: string): boolean {
  return (
    !isEmpty(value) &&
    (isContainsDigit(value) || isContainsSpecialChar(value)) &&
    isContainsNoWhitespace(value) &&
    (isContainsLowercaseLetter(value) || isContainsUppercaseLetter(value)) &&
    isContainsMinChar(value)
  );
}

export function isEmailValid(value: string): boolean {
  return !isEmpty(value) && /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
}

export function isPhoneNumberValid(value: string): boolean {
  return !isEmpty(value) && /^\+\d{1}\s\(\d{3}\)\s\d{3}-\d{4}$/.test(value); // Example: +1 (123) 456-7890
}

export function getFormattedPhoneNumber(value: string): string {
  // Remove all non-digit characters
  const digitsOnly = value.replace(/\D/g, '');

  // Apply formatting
  let formattedNumber = digitsOnly.replace(/^(\+?1)?(\d{0,3})?(\d{0,3})?(\d{0,4})?/, (match, p1, p2, p3, p4) => {
    let formatted = '+1';
    if (p2) formatted += ` (${p2}`;
    if (p3) formatted += `) ${p3}`;
    if (p4) formatted += `-${p4}`;
    return formatted;
  });

  return formattedNumber.slice(0, 17); // Example: +1 (300) 400-5000
}

export function getCleanPhoneNumber(value: string): string {
  return value.replace(/\D/g, ''); // Example: +11234567890
}

export const passwordAlertMessage = (password: string): string | undefined => {
  if (!isEmpty(password)) {
    if (!isContainsNoWhitespace(password)) {
      return 'Password must not contain any spaces';
    }
    if (!isContainsMinChar(password)) {
      return 'Password must be at least 8 characters';
    }
    if (!isContainsLowercaseLetter(password) && !isContainsUppercaseLetter(password)) {
      return 'Password must contain at least one letter';
    }
    if (!isContainsDigit(password) && !isContainsSpecialChar(password)) {
      return 'Password must contain at least one number or special character';
    }
  }
};

export const emailAlertMessage = (email: string): string | undefined => {
  if (!isEmpty(email) && !isEmailValid(email)) {
    return 'Not a valid email';
  }
};

export const phoneNumberAlertMessage = (email: string): string | undefined => {
  if (!isEmpty(email) && !isPhoneNumberValid(email)) {
    return 'Not a valid phone number';
  }
};

export const minMaxCheck = (event): string => {
  let { value, min, max } = event.target;
  return minMaxValueCheck(value, min, max);
};

export const minMaxValueCheck = (value: string, min: number, max: number): string => {
  if (checkIsNumber(value)) {
    const newValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    return value.length === 2 && newValue < 10 ? `0${newValue}` : `${newValue}`;
  }
  return value;
};

export const checkIsNumber = (value: Nullable<string>): boolean => {
  return !!value && !isNaN(Number(value));
};

export const getQueryParamObject = (queryString: Nullable<string>): any => {
  if (queryString) {
    return queryStringParser.parse(queryString);
  }
  return {};
};

export const goTo = (url: string, navigate: any, state?: Nullable<any>) => {
  return () => {
    navigate(url, { state });
  };
};

export const getKokoMapUrl = (userId: Nullable<string>, timeZone: Nullable<string>, fromDevice: boolean): Nullable<string> => {
  if (timeZone) {
    const currentHour = DateTime.now().setZone(timeZone).get('hour');
    let startDate = DateTime.now().setZone(timeZone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    if (currentHour < 18) {
      startDate = startDate.minus({ hours: 24 });
    }
    const endDate = startDate.plus({ hours: 24 });
    const url = `/user/${userId}/kokoMap?startTs=${startDate.toISO()}&endTs=${endDate.toISO()}` + (fromDevice ? '&fromDevice=true' : '');
    return url;
  }
};

export const goToKokoMap = (userId: string, timeZone: Nullable<string>, navigate: any, fromDevice: boolean) => {
  const url = getKokoMapUrl(userId, timeZone, fromDevice);
  if (url) {
    navigate(url);
  }
};

export const ellipsify = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  }
  return text;
};

export const mapsAreEqual = (map1: Map<any, any>, map2: Map<any, any>): boolean => {
  let testVal;
  if (map1.size !== map2.size) {
    return false;
  }
  for (let entry of Array.from(map1.entries())) {
    let key = entry[0];
    let val = entry[1];
    testVal = map2.get(key);
    if (testVal !== val || (testVal === undefined && !map2.has(key))) {
      return false;
    }
  }
  return true;
};

export const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => arr1?.length == arr2?.length && arr1.every((element, index) => element === arr2[index]);

export const areEqualBlank = (s1?: Nullable<string>, s2?: Nullable<string>): boolean => s1 === s2;

export const areEqualZero = (n1?: Nullable<number>, n2?: Nullable<number>): boolean => (n1 || 0) === (n2 || 0);

export const extractRouted = (location: any): string | undefined => {
  return extractRouteParam('routed', location);
};

export const extractRouteParam = (param: string, location: any): any | undefined => {
  const locationState = location?.state;
  return (locationState as any)?.[param];
};

export const reduxActionPrefix = (reduxAction: string) => reduxAction.substring(0, reduxAction.lastIndexOf('-'));

export const deviceShipmentSorter = (a: DeviceShipment, b: DeviceShipment): number => {
  const receivedA: Nullable<string> = a?.deviceReceivedTs;
  const receivedB: Nullable<string> = b?.deviceReceivedTs;
  if (receivedA && !receivedB) {
    return -1;
  } else if (receivedB && !receivedA) {
    return 1;
  } else if (receivedA !== receivedB && receivedA && receivedB) {
    return receivedA?.localeCompare(receivedB);
  } else {
    const shippedA: Nullable<string> = a?.deviceShippedTs;
    const shippedB: Nullable<string> = b?.deviceShippedTs;
    if (shippedA && !shippedB) {
      return -1;
    } else if (shippedB && !shippedA) {
      return 1;
    } else if (shippedA === shippedB) {
      return 0;
    }
    return shippedB || shippedB === '0' ? shippedA?.localeCompare(shippedB) || 0 : 0;
  }
};

export const getMaxKeyOfMaps = (...maps: Map<number, any>[]): Nullable<number> => {
  let maxKey: number | undefined = undefined;
  for (const map of maps) {
    for (const key of Array.from(map.keys())) {
      if (maxKey === undefined || key > maxKey) {
        maxKey = key;
      }
    }
  }
  return maxKey;
};

export const getFirmwareTypeForHwRevision = (hwRevision: Nullable<HwRevision>, hwRevisionFirmware: HwRevisionFirmware[]): FirmwareType => {
  return hwRevisionFirmware.find((x) => x.hwRevision === hwRevision)?.firmwareType || FirmwareType.UNKNOWN;
};

export const transferKgToLbs = (weight: number) => {
  return weight * 2.205;
};

export const getInitials = (fullName: string): string => {
  return fullName
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join(' ');
};

export const mergeArraysToMap = (keys: string[], values: string[]): { [key: string]: string } => {
  const map: { [key: string]: string } = keys.reduce((map: { [key: string]: string }, key, index) => {
    map[key] = values[index];
    return map;
  }, {});

  return map;
};
